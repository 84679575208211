*{
    margin: 0;
    padding: 0;
    font-family: Muli, 'sans-serif';
    transition: background-color 200ms ease-in-out;
}

html, body{
    background-color: var(--background);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden!important;
    overflow-y: auto!important;
}

#root{
    width: 100vw;
    background-color: var(--background);
    height: 100vh;
    overflow-x: hidden!important;
    overflow-y: auto!important;
}
#root > div{
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background: #F7F7F7;
}

::-webkit-scrollbar-thumb {
    background: var(--primary);
}

::-webkit-scrollbar-thumb:hover {
    background: var(--primary);
}

.themeToggleBtn{
    position: absolute;
    top: 10px;
    right: 15px;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
}
.theme-light{
    background: rgb(255, 255, 255);
    border: 1px solid rgb(208, 208, 208);
}
.theme-icon-light{
    color: #eaea00;
}
.theme-dark{
    background: rgb(38, 38, 38);
    border: 1px solid rgb(94, 94, 94);
}
.theme-icon-dark{
    color: #3a95ff;
}

.outer-container{
    background-color: var(--container);
    color: var(--textColor);
    min-width: 400px;
    width: 80vw;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.inner-container{
    background-color: var(--container);
    color: var(--textColor);
    width: 80%;
    max-width: 900px;
    padding: 5px;
}
.content-container{
    min-height: calc(100vh - 100px);
}

.navbar{
    padding-top: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.navbar .icon img{
    height: 55px;
    width: 55px;
}
.navbar .buttons{
    display: flex;
    justify-content: center;
    align-items: center;
}
.navbar .buttons a, .navbar .buttons span{
    padding: 10px 8px 8px 8px;
    color: white;
    text-decoration: none;
    margin-left: 5px;
    margin-right: 5px;
    transition: background-color 200ms linear;
}
.navbar .buttons a:hover, .navbar .buttons span:hover{
    filter: brightness(0.85);
    cursor: pointer;
}

.dropBtn{
    background-color: transparent;
    border-radius: 0;
    border: none;
    width: 20px;
    height: 20px;
    cursor: pointer;
    display: none;
}

.banner{
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.banner img{
    max-width: 800px;
    width: 100%;
}
.menu{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu a, .menu span{
    color: darkgray;
    text-decoration: none;
    border-radius: 20px;
    padding: 10px 8px 8px 8px;
    margin-left: 5px;
    margin-right: 5px;
    transition: background-color 100ms linear;
    background-color: rgba(0, 0, 0, 0.02);
}
.menu a:hover, .menu span:hover{
    filter: brightness(0.85);
    cursor: pointer;
}
.menu .active{
    background-color: var(--primary);
    color: var(--menuText);
}
strong{
    font-weight: 900;
}
.footer{
    max-width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;
    color: var(--textColor);
}
.footer .logo{
    width: 50%;
    display: flex;
    justify-content: center;
    text-align: center;
}
.footer .logo img{
    height: 50px
}
.footer .nav{
    width: 50%;
    display: flex;
    justify-content: center;
    text-align: end;
    flex-wrap: wrap;
}
.footer .nav a{
    color: var(--footerText);
    text-decoration: none;
    margin: 5px;
    transition: color 200ms linear;
    white-space: nowrap;
}
.footer .nav a:hover{
    color: var(--footerTextHover);
}
.footer .nav span{
    color: var(--footerText);
    text-decoration: none;
    margin: 5px;
    white-space: nowrap;
}
.footer .nav span:hover{
    color: var(--footerTextHover);
}

.overview .description{
    margin: auto;
    margin-top: 20px;
    width: 80%;
    text-align: center;
}

.overview .gifs{
    width: 90%;
    margin: 20px auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, auto));
}
.overview .gifs .heading {
    position: relative;
    grid-column: 1 / span 2;
    text-align: center;
    height: 24px;
    margin: 25px 0 10px 0;
    display: flex;
}
.overview .gifs .heading .lore{
    width: fit-content;
    position: relative;
}
.overview .gifs .heading .line{
    flex-grow: 1;
    margin-top: calc(20px / 2);
    margin-left: 40px;
    margin-right: 40px;
    height: 2px;
    background-color: var(--primary);
}

.overview .gif{
    width: 400px;
    margin: auto;
}
.overview .gif img{
    width: 400px;
    border-radius: 10px;
}
.overview .gif video{
    width: 400px;
    border-radius: 10px;
}
.overview .gif-span2{
    grid-column: span 2 / auto;
}
.overview .gifLightBox img{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.overview .gifLightBox video{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.overview .gifLightBox .gifLightBoxLore{
    margin-top: -3px;
    background: rgba(0, 0, 0, 0.8);
    height: 50px;
    font-size: 25px;
    color: white;
    text-align: center;
    padding: 12px 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.overview .features{
    margin: 20px auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, auto));
    gap: 10px;
}

.overview .features .feature{
    width: 350px;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.overview .features .feature .icon{
    width: 40px;
    height: 40px;
    margin-bottom: 5px;
    font-size: 40px;
    color: var(--primary);
}
.overview .features .feature .title{
    color: var(--featureTitleColor);
}
.overview .features .feature .desc{
    text-align: center;
    color: var(--featureTextColor);
}

.overview .pluginBanners{
    width: 100%;
    margin: 20px auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, auto));
    gap: 10px;
}
.pluginBanner{
    width: 100%;
    margin: 15px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-basis: calc(50% - 30px);
    cursor: pointer;
    background: var(--container);
    border-radius: 5px;
    overflow: hidden;
}
.pluginBanner a{
    text-decoration: none;
}
.pluginBanner .logo{
    position: absolute;
    top: 0;
    left: 0;
    margin: 15px;
    height: 40px;
    width: 40px;
}
.pluginBanner .price{
    position: absolute;
    top: 0;
    right: 0;
    margin: 15px;
    font-weight: 900;
    color: var(--textColor);
}
.pluginBanner .bannerPlugin{
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    flex-grow: 1;
    width: 100%;
    z-index: 5;
    transition: transform 200ms ease-in-out;
    transform: scale(0.8);
}
.pluginBanner .bannerPlugin img{
    max-width: 800px;
    width: 100%;
    height: 165px;
}
.pluginBanner .gradiant{
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 55px;
    left: 0;
    right: 0;
    transition: opacity 200ms ease-in-out;
    background: rgb(1,227,203);
    opacity: 0;
}
.pluginBanner:hover .gradiant{
    opacity: 1;
}
.pluginBanner:hover .bannerPlugin{
    transform: scale(0.9);
}
.pluginBanner .text{
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    padding: 15px;
    width: 95%;
    height: 30px;
    background: rgba(0, 0, 0, 50%);
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 18px;
    word-wrap: break-word;
}

.discord{
    margin: 20px auto;
    max-width: 600px;
}
.discord iframe{
    width: 100%;
}
ul {
    list-style-type: square;
}
li{
    margin-left: 20px;
    list-style: outside square;
    padding-left: 0;
    margin-bottom: 5px;
}
code{
    font-family: source-code-pro,Menlo,Monaco,Consolas,"Courier New",monospace;
    font-weight: 900;
    margin: 0 2px;
    word-wrap: break-word;
}
.color-primary{
    color: var(--primary);
}
.code-div{
    position: relative;
    padding-right: 20px;
}
.code-div .code-copy{
    position: absolute;
    top: 10px;
    right: 10px;
    transition: color 200ms linear;
    font-size: 22px;
}
.code-div .code-copy:hover{
    color: var(--primary);
    cursor: pointer;
}
table{
    margin: 10px 0;
    border-collapse: collapse;
}
td, th {
    border: 1px solid #ddd;
    padding: 8px;
}
tr:hover {
    background-color: #f2f2f2;
}
th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: var(--primary);
    color: white;
}
.card{
    border-left: 3px solid rgba(227, 0, 255, 1);
    background-color: rgba(227, 0, 255, 0.2);
    padding: 15px 10px;
    color: black;
    margin: 20px 0;
    position: relative;
    overflow: hidden;
}
.card h1, .card h2, .card h3{
    font-size: 20px;
    margin-left: 30px;
    margin-bottom: 5px;
    color: black;
    overflow-wrap: break-word;
}
.card p{
    margin-left: 30px;
    margin-bottom: 5px;
    color: black;
    overflow-wrap: break-word;
}
.success{
    border-color: rgba(80, 175, 81, 1);
    background-color: rgba(80, 175, 81, 0.1);
}
.success h1, .success h2, .success h3, .success h4{
    color: #489e49;
}
.success h1:before, .success h2:before, .success h3:before, .success h4:before{
    content: '👍';
    position: absolute;
    top: 6px;
    left: 5px;
    font-size: 22px;
}
.info{
    border-color: rgba(91, 192, 222,1);
    background-color: rgba(91, 192, 222,0.1);
}
.info h1, .info h2, .info h3, .info h4{
    color: #46b8da;
}
.info h1:before, .info h2:before, .info h3:before, .info h4:before{
    content: '📘';
    position: absolute;
    top: 8px;
    left: 5px;
}
.warning {
    border-color: rgba(240, 173, 78, 1);
    background-color: rgba(240, 173, 78, 0.1);
}
.warning h1, .warning h2, .warning h3 .warning h4{
    color: #eea236;
}
.warning h1:before, .warning h2:before, .warning h3:before, .warning h4:before{
    content: '🚧';
    position: absolute;
    top: 8px;
    left: 5px;
}
.error{
    border-color: rgba(217, 83, 79, 1);
    background-color: rgba(217, 83, 79, 0.1);
}
.error h1, .error h2, .error h3, .error h4{
    color: #d43f3a;
}
.error h1:before, .error h2:before, .error h3:before, .error h4:before{
    content: '❗';
    position: absolute;
    top: 8px;
    left: 5px;
}
.PageNotFound{
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.PageNotFound h1{
    font-size: 100px;
}
.PageNotFound p{
    font-size: 50px;
}
.PageNotFound a{
    font-size: 30px;
    padding: 10px 8px 8px 8px;
    color: white;
    text-decoration: none;
    margin-top: 20px;
    transition: background-color 200ms linear;
    width: 40%;
    text-align: center;
    background-color: var(--primary);
}

.updates{
    margin-top: 20px;
    padding-inline: 20px;
}
.update{
    margin-bottom: 20px;
    background-color: rgba(0, 0, 0, 0.01);
    padding: 5px;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 10px;
}
.update .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.headerTitle{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.headerTitle .version{
    background-color: var(--primary);
    padding: 5px 5px 2px;
    font-size: 20px;
    color: white;
    font-weight: bold;
    margin-right: 5px;
}
.headerTitle .title{
    font-size: 18px;
}
.header .date{
    font-size: 20px;
    color: var(--textColor);
}
.update .body{
    padding: 50px 20px;
}
.update .body img{
    width: 100%;
}
.Wiki{
    margin-top: 20px;
    padding-inline: 5px;
}
html, body{
    /*height: 100vh;*/
}
.row{
    display: flex;
}
.colmn-80{
    width: 80%;
    margin-right: 5px;
}
.colmn-20{
    width: 20%;
}
.wiki-container{
    padding: 5px;
}
.wiki-container img {
    width: 100%;
}
.wikiPageContent{
    padding-inline: 22px;
    padding-block: 20px;
    background-color: var(--wikiPageContentBg);
    color: var(--wikiPageContentTitle);
    position: relative;
}
.wikiPageContent p, .wikiPageContent li{
    color: var(--wikiPageContentText);
}
.editButton{
    position: absolute;
    top: -10px;
    right: 10px;
}
.editButton a{
    color: var(--wikiPageContentText);
    text-decoration: none;
    font-size: 15px;
}
.wikiMenu{
    position: -webkit-sticky;
    position: sticky;
    top: 20px;
}
.wikiMenuTitle{
    font-size: 25px;
    text-align: center;
    padding: 20px 10px;
}
.wikiTitle{
    font-size: 25px;
    text-align: center;
    padding: 20px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.wikiTitle .icon{
    background-color: var(--primary);
    width: 45px;
    height: 45px;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
.wikiMenuItem{
    background-color: var(--wikiButtonBg);
    color: var(--wikiButtonText);
    padding: 15px;
    border: 1px solid transparent;
    border-bottom-color: #d2d2d2;
    border-radius: 5px;
    transition: all 100ms ease-in-out;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    position: relative;
    height: 30px;
}
.wikiMenuItem.hasSubMenu{
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.subItems{
    position: absolute;
    right: -160px;
    top: 0;
    width: 150px;
    background-color: var(--wikiButtonBg);
    border: 0 solid transparent;
    border-radius: 0 0 5px 5px;
    display: none;
    z-index: 10;
    -webkit-box-shadow: 10px 10px 35px -20px rgb(181, 181, 181);
    -moz-box-shadow: 10px 10px 35px -20px rgba(181, 181, 181,1);
    box-shadow: 10px 10px 35px -20px rgb(181, 181, 181);
    transition: all 100ms ease-in-out;
    max-height: 300px;
    overflow: auto;
    overflow-x: hidden;
}
.subItems .subMenuSearch{
    border-bottom: 1px solid #d2d2d2;
    position: sticky;
    top: 0;
    width: 100%;
}
.subItems .subMenuSearch .subMenuSearchInput{
    padding: 10px;
    border: none;
    font-size: 16px;
    width: calc(150px - 8px);
}
.subItems .noResultsFoundText{
    color: #1e1e1e;
    font-size: 12px;
    text-align: center;
    padding: 5px;
}
.subItems .subMenuSearch .subMenuSearchInput:focus{
    outline: none;
    border: none;
}
.subItemsBelow{
    position: relative;
    display: block;
    left: 20px;
    right: 0;
    width: calc(100% - 20px);
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}
.subItems .subItem{
    color: var(--wikiButtonText);
    padding: 5px;
    margin-block: 2px;
    border: 0 solid transparent;
}
.subItems .subItem:hover{
    background-color: var(--wikiButtonHoverBg);
    color: var(--wikiButtonHoverText);
    cursor: pointer;
}
.wikiMenuItem:hover{
    background-color: var(--wikiButtonHoverBg);
    color: var(--wikiButtonHoverText);
    cursor: pointer;
}
.hoverSpacer{
    position: absolute;
    right: -80px;
    top: 0;
    width: 50px;
    background: transparent;
    height: 30px;
    padding: 15px;
    border: 0 solid transparent;
    z-index: 5;
    display: none;
}
.wikiMenu:hover > .subItems {
    display: block;
}
.wikiMenu:hover > .hoverSpacer {
    display: block;
}

.wikiMenuItem .icon{
    margin-right: 5px;
    font-size: 25px;
}
.wikiMenuItem .text{
    width: 100%;
    text-align: center;
    font-size: 18px;
}
.subItems .active > div{
    background-color: var(--wikiButtonActiveBg);
    color: var(--wikiButtonActiveText);
}
.subItems .active:hover > div{
    background-color: var(--wikiButtonActiveHoverBg);
    color: var(--wikiButtonActiveHoverText);
}
.wikiMenu .active{
    background-color: var(--wikiButtonActiveBg);
    color: var(--wikiButtonActiveText);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.wikiMenu .active:hover{
    background-color: var(--wikiButtonActiveHoverBg);
    color: var(--wikiButtonActiveHoverText);
}
.wikiPageNav{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-top: 20px;
}
.wikiPrevDiv{
    width: 100%;
}
.wikiNextDiv{
    width: 100%;
}
.wikiPageNavBtn{
    width: 100%;
    height: 80px;
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    transition: all 100ms ease-in-out;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}
.wikiPageNavHidden{
    display: none;
}
.wikiPrevBtn{
    flex-direction: row-reverse;
    text-align: end;
}
.wikiNextBtn{
    text-align: start;
}
.wikiPageNavBtnText{
    width: calc(100% - 50px);
}
.wikiPageNavBtnSubTitle{
    font-size: 12px;
    color: #8899A8;
    margin-bottom: 2px;
}
.wikiPageNavBtnTitle{
    font-size: 20px;
    color: #3B454E;
    font-weight: bold;
    transition: color 100ms ease-in-out;
}
.wikiPageNavBtnIcon{
    width: 50px;
    font-size: 25px;
    color: rgba(136,153,168,1.00);
    transition: color 100ms ease-in-out;
}
.wikiPageNavBtn:hover{
    border-color: var(--primary);
    cursor: pointer;
}
.wikiPageNavBtn:hover .wikiPageNavBtnTitle{
    color: var(--primary);
}
.wikiPageNavBtn:hover .wikiPageNavBtnIcon{
    color: var(--primary);
}

.popup-content{
    padding: 0!important;
    border: none!important;
    border-radius: 10px;
    width: auto!important;
    -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    background: var(--container)!important;
}
@keyframes anvil {
    0% {
        transform: scale(1) translateY(0px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    1% {
        transform: scale(0.96) translateY(10px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    100% {
        transform: scale(1) translateY(0px);
        opacity: 1;
        box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
}

.modal {
    font-size: 12px;
    border-radius: 10px;
    background: var(--container);
}
.modal > .header:not(:empty) {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    padding: 15px 10px 10px 10px;
    background: var(--primary);
    color: white;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
.modal > .content:not(:empty) {
    width: calc(100% - 60px);
    padding: 10px 30px;
    text-align: center;
    font-size: 16px;
    color: var(--textColor);
}
.modal > .actions {
    --buttonColor: transparent;
    --buttonColorHover: var(--wikiNoteBg);
    --buttonBorderColor: var(--wikiNoteBorder);
    --buttonBorderColorHover: var(--wikiNoteBorder);
    --buttonTextColor: var(--textColor);
    --buttonHoverTextColor: var(--textColor);

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
    padding: 10px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    max-width: 500px;
}
.modal > .actions a{
    text-decoration: none;
    color: var(--buttonTextColor);
    background: var(--buttonColor);
    border: 1px solid var(--buttonBorderColor);
    border-radius: 5px;
    padding: 8px 10px 5px 10px;
    transition: all 100ms ease-in-out;
    font-size: 22px;
    text-align: center;
}
.modal > .actions a:hover{
    background-color: var(--buttonColorHover);
    border-color: var(--buttonBorderColorHover);
    color: var(--buttonTextColorHover);
    cursor: pointer;
}
.modal > .actions a img{
    width: 20px;
    height: 20px;
    margin-bottom: -5px;
    margin-right: 5px;
}
.modal > .actions-vert {
    flex-direction: column;
}
.modal > .actions-vert a{
    width: 50%;
}
.modal > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -12px;
    top: -12px;
    font-size: 24px;
    font-weight: bold;
    background: white;
    color: black;
    border-radius: 18px;
    border: 2px solid var(--primary);
    transition: color 0.2s ease-in-out, background 0.2s ease-in-out;
}
.modal > .close:hover {
    color: var(--primary);
}
.spoiler{
    width: max-content;
    position: relative;
    cursor: pointer;
    transition: background-color 100ms ease-in-out;
}
.spoiler * {
    width: max-content;
    z-index: 30000;
}
.spoiler:hover::before{
    background: rgb(50, 50, 50);
}
.spoiler:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(30, 30, 30);
    border-radius: 4px;
    z-index: 50000;
    transition: all 200ms ease-in-out;
}
.spoiler-open{
    cursor: text;
    background: rgba(20, 20, 20,0.1);
    border-radius: 4px;
}
.spoiler-open:hover{
    background: rgba(50, 50, 50,0.1);
}
.spoiler-open:before{
    display: none;
}
@keyframes pulse {
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(1.02);
    }
    100%{
        transform: scale(1);
    }
}

.announcement-container{
    display: flex;
    padding: 5px;
    background: rgba(255, 150, 0, 0.3);
    border: 2px solid rgba(255, 150, 0, 0.8);
    border-radius: 5px;
    margin-block: 10px;

    transition: transform 200ms ease-in-out;
    transform: scale(1);
    animation: pulse 4s linear infinite;
    animation-fill-mode: both;
}
.announcement-container .text{
    padding-inline: 5px;
    padding-block: 2px;
    width: 100%;
}
.announcement-container img{
    width: 64px;
    aspect-ratio: 1/1;
}
.announcement-container button{
    width: 100%;
    height: 100%;
    border-radius: 5px;
    padding: 10px;
    transition:
            background-color 200ms ease-in-out,
            color 200ms ease-in-out,
            border 200ms ease-in-out;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;

    background-color: #d74637;
    border: 2px solid #e74c3c;
    color: white;
}
.announcement-container button:hover{
    background-color: rgb(208, 80, 80);
    border-color: #dc3a3a;
    color: #e3e3e3;
}

a{
    color: var(--wikiPageContentLink);
}

.bstats{
    margin: 20px auto;
    text-align: center;
}

.bstats .headlines{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-block: 15px;
}

.bstats .headlines .headline{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background: var(--bStatsHeadlineBg);
    color: var(--bStatsHeadlineText);
    font-size: 16px;
    font-weight: bold;
}

.bstats .headlines .headline .icon{
    margin-right: 15px;
}

.bstats .charts{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 50px;
}

.bstats .charts .chart .chart-title{
    font-size: 20px;
}
.bstats .charts .chart .chart-content {
    display: flex;
    justify-content: center;
}

.bstats .pies-row {
    display: flex;
    justify-content: space-evenly;
    gap: 20px;
    margin-top: 50px;
}
.bstats .pies-row .pie .pie-content{
    display: flex;
    justify-content: center;
    gap: 10px;
}
.bstats .pies-row .pie .pie-title{
    font-size: 20px;
}
.bstats .pies-row .pie .pie-content .pie-labels{
    display: flex;
    justify-content: center;
    gap: 5px;
    flex-direction: column;
}
.bstats .pies-row .pie .pie-content .pie-label{
    display: flex;
}
.bstats .pies-row .pie .pie-content .pie-label .chart-label-color{
    width: 15px;
    height: 15px;
    margin-right: 10px;
    border-radius: 50%;
}

.bstats .chart-tooltip{
    border: none!important;
    padding: 5px!important;
    margin: 0!important;
    background: rgba(255, 255, 255, 0.8)!important;
    display: flex;
    flex-direction: column;
    color: #000000;
    text-align: start;
}
.bstats .chart-tooltip p{
    margin: 0!important;
    padding: 0!important;
}
.bstats .chart-tooltip .tooltip-title{
    font-weight: bold;
    font-size: 20px;
}

.bstats .pie-chart-label{
    fill: #fff;
    height: 20px;
}

.recharts-tooltip-wrapper, .recharts-tooltip-wrappe:active, .recharts-tooltip-wrapper:focus, .recharts-tooltip-wrapper:hover,
.chart-tooltip, .chart-tooltip:active, .chart-tooltip:focus, .chart-tooltip:hover,
.recharts-pie-sector, .recharts-pie-sector:active, .recharts-pie-sector:focus, .recharts-pie-sector:hover,
.recharts-sector, .recharts-sector:active, .recharts-sector:focus, .recharts-sector:hover{
    border: none!important;
    outline: none!important;
}

.test-server-container{
    display: flex;
    gap: 10px;
    margin: 20px auto;
    border: 4px solid var(--primary);
    height: 85px;
    width: 90%;
}
.test-server-container .icon{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-right: 4px solid var(--primary);
    height: 65px;
}
.test-server-container .icon img{
    width: 64px;
    height: 64px;
}
.test-server-container .contents {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
}
.test-server-container .contents .text{
    padding: 10px;
    width: 100%;
}
.test-server-container .contents .text h1{
    font-size: 25px;
}
.test-server-container .contents .text p{
    font-size: 15px;
}
.test-server-container .contents .info-text{
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    width: fit-content;
}
.test-server-container .contents .info-text .copy-click-input{
    width: fit-content;
    text-align: end;
    font-size: 28px;
    padding: 5px;
}
.input-icon{
    position: relative;
}
.input-icon .copy-icon{
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    transition: transform 0.2s ease-in-out;
    font-size: 20px;
    pointer-events: none;
}
.copy-click-input{
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    background: none;
    color: var(--textColor);
    transition: color 0.2s ease-in-out;
}
.copy-click-input:hover{
    cursor: pointer;
    color: var(--primary);
}

@media only screen and (max-width: 1400px) {
    .inner-container{
        width: 100%;
    }
}

@media only screen and (max-width: 1125px) {
    .outer-container{
        width: 100vw;
    }
    .subItems{
        display: block;
        position: relative;
        width: 100%;
        left: 0;
        border-top-right-radius: 0;
    }
    .hoverSpacer{
        display: none;
    }
}

@media only screen and (max-width: 1000px) {
    .row{
        flex-direction: column-reverse;
    }
    .colmn-80{
        width: 100%;
        margin-right: 0;
    }
    .colmn-20{
        width: 100%;
    }
    .wikiMenu{
        position: relative;
    }
    .subItems{
        display: block;
        position: relative;
        width: 100%;
        left: 0;
        border-top-right-radius: 0;
    }
    .hoverSpacer{
        display: none;
    }
    .wikiContent{
        margin-top: 30px;
    }
    .subItems .subMenuSearch .subMenuSearchInput{
        width: calc(100% - 5px);
    }
}

@media only screen and (max-width: 905px) {
    .overview .gifs{
        width: 85%;
    }
    .overview .gif-span2{
        grid-column: auto;
    }
    .bstats .headlines{
        flex-direction: column;
        gap: 10px;
    }
    .bstats .pies-row{
        flex-direction: column;
        gap: 10px;
    }
    .bstats .charts .chart{
        width: 100%;
    }
    .test-server-container{
        height: fit-content;
        width: fit-content;
    }
    .test-server-container .icon{
        height: unset;
    }
    .test-server-container .contents{
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .test-server-container .contents .info-text{
        justify-content: flex-start;
        align-items: flex-start;
    }
    .test-server-container .contents .info-text .copy-click-input{
        text-align: start;
    }
}

@media only screen and (max-width: 800px) {
    .overview .gifs{
        width: 100%;
        grid-gap: 0px;
    }
}

@media only screen and (max-width: 550px) {
    .popup-content .modal{
        width: 85vw;
    }
}

@media only screen and (max-width: 600px) {
    .discord{
        width: 95vw;
        padding-right: 5px;
    }
    .menu{
        width: 80vw;
        display: flex;
        flex-wrap: wrap;
        margin-inline: auto;
    }
    .update .header{
        display: block;
    }
    .update .body{
        padding: 20px 10px;
    }
    .overview .features{
        grid-template-columns: none;
    }
    .overview .features .feature{
        width: 250px;
        margin: 20px auto;
    }
    .overview .gifs{
        grid-template-columns: none;
    }
    .overview .gif{
        width: unset;
    }
    .overview .gif img{
        width: 350px;
    }
    .dropBtn{
        display: block;
    }
    .dropdown{
        width: 50px;
        margin-right: 20px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .dropdown:hover .buttons {
        display: flex;
    }
    .navbar{
        padding-inline: 30px;
        width: 90%;
    }
    .navbar .buttons{
        display: none;
        flex-direction: column;
        position: absolute;
        right: 0;
        top: 40px;
        background-color: rgba(255,255,255,0.8);
        border: 1px solid black;
        border-radius: 5px;
        padding: 5px 2px 2px;
        width: 50vw;
        z-index: 10;
    }
    .navbar .buttons a{
        width: calc(100% - 20px);
        margin-bottom: 2px;
    }
    .banner img{
        width: 100vw;
    }
    .wiki-container{
        padding-inline: 20px;
    }
    .card{
        margin: 20px 0;
    }
    li{
        margin-bottom: 10px;
    }
    .footer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 80%;
        margin: auto;
    }
    .footer .logo{
        width: auto;
    }
    .footer .nav{
        width: auto;
        padding: 0 20px;
    }
    .footer .nav a{
        display: block;
        word-wrap: break-word;
        width: 90%;
        white-space: normal;
        text-align: center;
    }
}

@media only screen and (max-width: 480px) {
    .test-server-container{
        flex-direction: column;
    }
    .test-server-container .icon{
        border-right: none;
        border-bottom: 4px solid var(--primary);
    }
    .test-server-container .icon img{
        width: 150px;
        height: 150px;
    }
    .test-server-container .contents{
        text-align: center;
    }
    .test-server-container .contents .text{
        width: 75vw;
        margin-inline: auto;
        padding-bottom: 0;
    }
    .test-server-container .contents .info-text p{
        width: 100%;
    }
    .test-server-container .contents .info-text .copy-click-input{
        margin: auto;
    }
    .test-server-container .contents .info-text{
        text-align: center;
    }
    .overview .gifs{
        width: 100vw;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .overview .gifs .heading{
        width: 100vw;
        margin-bottom: 0;
        margin-top: 20px;
    }
    .overview .gifs .heading .line{
        display: none;
    }
    .overview .gifs .heading .lore{
        width: 100%;
    }
    .overview .gifs .gif{
        width: fit-content;
    }
    .overview .gifs .gif img{
        margin-inline: auto;
    }
}

@media only screen and (max-width: 450px) {
    .overview .gif img{
        width: 80vw;
    }
    .popup-content .modal > .actions-vert a{
        width: calc(100% - 40px);
    }
}